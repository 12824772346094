$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

hostel-index-address {
  .hostel-contact-map {
    height: 400px;
    position: relative;

    .card {
      width: 350px;
      position: absolute;
      top: 11px;
      left: 50px;
      z-index: 10;
      font-size: 1.1em;
      cursor: pointer;

      @media (max-width: 700px) {
        right: 50px;
        width: inherit;
      }
    }

    .fas {
      color: $like;
      margin-right: 10px;
    }
    .fa-map-marker-alt {
      margin-left: 2px;
      margin-right: 12px;
    }
  }
}

$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";
$carousel-zindex: $zindex-modal;

hostel-room {
  h3 {
    font-weight: 400;
    font-size: 1.5em;
  }

  .hostel-room-price {
    position: absolute;
    top: -10px;
    right: -10px;
    z-index: 1;

    @media (max-width: 410px) {
      left: 0;
      right: 0;
    }
  }

  .card {
    box-shadow: 6px 6px 0 rgba(0, 0, 0, .1);
    min-height: 275px;

    .card-body {
      padding: 0;
    }

    .card-title, .card-text {
      margin-left: 10px;
    }

    .card-title {
      margin-top: 10px;
    }

    .card-room-carousel {
      display: block;
      & > * {
        display: inline-block;
        float: left;
      }
    }

    .card-room-description {
      padding-right: 20px;
      clear: both;
    }

    .card-room-booking {
      margin-top: 10px;
      margin-left: 10px;
      margin-bottom: 10px;
    }

    @media (min-width: 700px) {
      .card-room-description {
        clear: none;
      }
    }

    @media (min-width: 768px) {
      .card-room-carousel {
        display: inline-block;
        float: left;
      }
      .card-room-description {
        overflow-y: auto;
        max-height: 210px;
      }
      .card-room-booking {
        position: absolute;
        bottom: 3px;
        left: 325px;
      }
    }
  }

  .card-room-carousel .carousel-item {
    > img, &, &:before {
      width: 250px;
      height: 275px;
      user-select: none;
    }
    > img {
      display: block;
    }
  }

  // font preload for hover
  .font-awesome-5-free-preload {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    left: -9999px
  }

  .card-room-carousel .carousel-item.active {
    position: relative;
    cursor: pointer;

    &:hover:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f31e";
      color: white;
      font-size: 2.5em;
      line-height: 275px;
      display: block;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(226, 12, 48, .6);
    }
  }

  .carousel-indicators-thumb {
    list-style: none;
    padding: 0;

    li {
      img {
        cursor: pointer;
        width: 55px;
        height: 55px;
        border-radius: 5px;
        transition: opacity 500ms ease-in-out;
      }
      &.active img {
        opacity: 0.3;
      }
    }
  }

  .card-room-carousel .carousel-indicators-thumb {
    margin: 2px 0 0 5px;

    li {
      margin: 10px;
    }
  }

  .carousel-indicators-switchers {
    width: 250px;
    list-style: none;
    padding: 0;
    display: block;
    text-align: center;

    li {
      display: inline-block;
      width: 7px;
      height: 7px;
      background-color: rgba(0, 0, 0, .2);
      border-radius: 50%;
      margin-left: 3px;
      margin-right: 3px;
      cursor: pointer;
    }

    li:hover {
      background-color: rgba(226, 12, 48, .45);
    }
    li.active {
      background-color: rgba(226, 12, 48, .65);
    }
  }

  .room-carousel-fullscreen {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: $carousel-zindex;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);

    .carousel {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .carousel-item {
      img {
        margin: 0 auto;
        max-height: 100vh;
      }
    }

    .carousel-indicators-thumb {
      list-style: none;
      padding: 0;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      height: 75px;
      margin: 0;

      li {
        display: inline-block;
        margin: 10px;

        img {
          position: relative;
          z-index: $carousel-zindex + 2;
        }
        &:after {
          content: "";
          position: relative;
          width: 55px;
          height: 55px;
          top: -55px;
          display: block;
          background-color: white;
          border-radius: 5px;
          z-index: $carousel-zindex + 1;
        }
      }
    }

    .close {
      color: white;
      cursor: pointer;
      font-size: 4em;
      position: absolute;
      width: 100px;
      height: 100px;
      top: 0;
      right: 0;
      z-index: $carousel-zindex + 2;
    }
  }

  .card-room-carousel {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0);
    }

    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(0, 100%, 0);
    }

    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(0, -100%, 0);
    }
  }

}

$staticUrl: 'https://static.likehostels.ru';
hostel-wubook {
  display: block;
  height: calc(100vh - 3.5rem);

  iframe {
    width: 100%;
    height: calc(100% - 4px);
    border: 0;
  }
}

$staticUrl: 'https://static.likehostels.ru';
hostel-booking-form {
  .form-control {
    display: inline-block;
    width: auto;
    margin-right: 10px;
    vertical-align: middle;

    @media (max-width: 770px) {
      display: block;
      margin: 0 auto 10px;
    }
  }
}

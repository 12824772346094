$staticUrl: 'https://static.likehostels.ru';
hostel-review {
  .card {
    border: 2px solid rgba(69, 102, 142, 1);
    box-shadow: 6px 6px 0 rgba(0, 0, 0, .1);

    .fab {
      position: absolute;
      right: 0;
      top: 0;
      width: 35px;
      height: 35px;
      font-size: 25px;
      text-align: center;
      padding-top: 5px;
      border-bottom-left-radius: 5px;
      background-color: rgba(69, 102, 142, 1);
      color: white;
    }
  }

  a.card-link, a.btn-link, a.card-link:hover, a.btn-link:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

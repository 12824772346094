$staticUrl: 'https://static.likehostels.ru';
$indicators-zindex: 10;

hostel-index-carousel {
  .carousel.hostel-index-carousel {
    position: relative;
    min-height: 300px;

    .carousel-inner {
      img {
        width: 100%;
        max-height: calc(100vh - 55px);
        object-fit: cover;
        min-height: 300px;
      }
    }

    .carousel-indicators-thumb {
      position: absolute;
      list-style: none;
      padding: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      height: 55px;
      overflow: hidden;

      @media (max-width: 770px) {
        display: none;
      }
      @media (max-height: 450px) {
        display: none;
      }

      > li {
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;

        img {
          border-radius: 5px;
          width: 55px;
          height: 55px;
          position: relative;
          transition: opacity 500ms ease-in-out;
          z-index: $indicators-zindex + 1;
        }
        &:after {
          content: "";
          position: relative;
          width: 55px;
          height: 55px;
          top: -55px;
          display: block;
          background-color: white;
          border-radius: 5px;
          z-index: $indicators-zindex;
        }
        &.active img {
          opacity: 0.3;
        }
      }
    }
  }
}

$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

hostel-index {
  h2 {
    font-weight: 300;
    font-size: 2.3em;
  }

  .hostel-index-rooms {
    background-image: url("assets/img/pattern.png");
  }

  .hostel-rooms-list {
    max-width: 1030px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
  }

  .hostel-promos {
    text-align: center;

    .hostel-promo {
      text-align: left;
      width: 310px;
      height: 180px;
      display: inline-block;
      margin-left: 10px;
      margin-right: 10px;
      vertical-align: middle;
      background-size: cover !important;
      background-position: center center;
      color: white;
      font-size: 1.3em;
      margin-bottom: 10px;

      h5 {
        font-size: 1em;
        font-weight: bold;
      }

      &, .hostel-promo-bg {
        border-radius: 5px;
      }

      .hostel-promo-bg {
        background-color: rgba(0, 0, 0, .35);
        padding: 20px;
        width: 100%;
        height: 100%;
      }
    }
  }

  .hostel-index {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .container {
      padding-top: 10%;
      h1 {
        color: white;
        font-size: 2.5em;
        font-weight: 700;
        text-shadow: black 0 0 2px;
      }
      @media (max-width: 770px) {
        padding-top: 5%;
        h1 {
          font-size: 2em;
        }
      }
      @media (max-width: 500px) {
        h1 {
          font-size: 1.5em;
        }
      }
    }
  }

}

$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

city-index {
  h1 {
    font-weight: 300;
    font-size: 2em;
  }

  .city-hostels {
    background-image: url("assets/img/pattern.png");

    .city-hostel-img {
      width: 40%;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover;
      background-position: center center;
      z-index: 0;

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    .city-hostel-shorten {
      z-index: 2;
      position: absolute;
      top: 0;
      right: 0;
      background-color: $like;
      color: white;
      font-size: 1.2em;
      font-weight: bold;
      text-align: center;
      width: 40px;
      height: 40px;
      border-bottom-left-radius: 100%;
    }

    .card-body {
      margin-left: 40%;
      z-index: 1;

      > .card-text {
        padding-right: 20px;
      }

      @media (max-width: 700px) {
        margin-left: 0;
        position: relative;
        color: white;
        font-weight: bold;

        > .card-text {
          background-color: rgba(0, 0, 0, .25);
          padding-left: 1em;
          padding-right: 1em;
          border-radius: 5px;
        }
      }
    }
  }

  .city-hostel-phone {
    font-weight: 300;
    font-size: 2em;
  }

  .city-hostles-map {
    min-height: 300px;
  }

}

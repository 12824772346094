$staticUrl: 'https://static.likehostels.ru';
city-description {
  .city-description-img {
    height: 320px;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    color: white;
    position: relative;

    h2 {
      position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      text-shadow: black 0 0 1px;
    }
  }

  .city-description-head {
    > img {
      max-width: 40%;
      margin: 15px;
    }
  }
}

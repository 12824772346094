$staticUrl: 'https://static.likehostels.ru';
@import "app/custom-variables";

$callme-zindex: $zindex-dropdown + 1;

hostel-callme {
  .callme {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: $callme-zindex;
  }

  .card {
    position: fixed;
    z-index: $callme-zindex + 1;
    width: 400px;
    height: 350px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;

    @media (max-height: 460px) {
      top: 55px;
      bottom: 0;
      transform: translate(-50%, 0);
    }

    .close {
      color: $like;
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .card-title {
      text-align: center;
      font-size: 1.5em;
    }
    .form-check-label {
      line-height: 1em;
      a {
        color: $like;
      }
    }
  }
}

$staticUrl: 'https://static.likehostels.ru';
mini-navbar {
  text-align: center;

  .likehostel-logo-navbar {
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: 410px) {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .likehostel-logo {
    top: 0;
    left: 0;
  }

  a {
    width: 110px;
    text-align: right;
  }
  .likehostel-logo-navbar + a {
    text-align: left;
  }
}

$staticUrl: 'https://static.likehostels.ru';
hostel-about {
  .btn.btn-like {
    &, &:hover {
      color: white;
      font-weight: bold;
    }
  }

  .hostel-about-image {
    height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    box-shadow: inset 0px -75px 45px rgba(0, 0, 0, .5);

    h1 {
      color: white;
      position: absolute;
      bottom: 0;
    }
  }
}
